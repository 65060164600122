import React from "react";
import {Link} from 'gatsby'
import {SiteMeta} from "../general/seo"

const Error12 = () => {
    return (
    <>
    <SiteMeta title="Coming Soon Page | Psaltry International Company Limited" description="We are working round the clock to improve your experience."/>
        <div className="bg-gray-800 h-screen">
            <div className="flex items-center justify-center py-12">
                <div className="bg-white border rounded-md flex items-center justify-center mx-4 md:w-2/3 ">
                    <div className="flex flex-col items-center py-16 ">
                        <img className="px-4 w-4/12 hidden md:block" src="assets/images/construzion.webp" alt="" />
                        <img className="md:hidden w-3/12" src="assets/images/construzion.webp" alt="" />
                        <h1 className="px-4 pt-8 pb-4 text-center text-5xl font-bold leading-10 text-gray-800">Hey Pal! </h1>
                        <p className="px-4 pb-10 text-xl leading-none text-center text-gray-600">We are working round the clock to improve your experience on our website. </p>
                        <Link to="/"><button className="mx-4 h-10 w-44 border rounded-md text-white text-base bg-sgreen hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-opacity-50 focus:ring-sgreen">Go Home</button></Link>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Error12;
